.grid {
  width: 100vmin;
  height: 100vmin;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.tile-10 {
  height: 10vmin;
  width: 10vmin;
}

.tile-50 {
  height: 2vmin;
  width: 2vmin;
}

.tile-100 {
  height: 1vmin;
  width: 1vmin;
}

.tile-current-10 {
  height: 1vmin;
  width: 1vmin;
  border: 4vmin solid lime;
}

.tile-current-50 {
  height: 0.2vmin;
  width: 0.2vmin;
  border: 0.8vmin solid lime;
}

.tile-current-100 {
  height: 0.1vmin;
  width: 0.1vmin;
  border: 0.4vmin solid lime;
}

.tile-win-10 {
  height: 1vmin;
  width: 1vmin;
  border: 4vmin solid lime;
}

.tile-win-50 {
  height: 0.2vmin;
  width: 0.2vmin;
  border: 0.8vmin solid lime;
}

.tile-win-100 {
  height: 0.1vmin;
  width: 0.1vmin;
  border: 0.4vmin solid lime;
}

.icon {
  height: 100%;
  width: 100%;
}
