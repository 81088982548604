.instructions-outerDiv {
  flex-direction: column;
  display: flex;
  margin: 10vmin;
  align-items: center;
}

.instructions {
  margin: 1vmin;
}

.grid-icon {
  height: 100px;
  width: 100px;
}

.grid-container {
  display: grid;
  grid-template-columns: 200px 110px 200px;
  align-items: center;
}

.grid-container h4,
img {
  margin: 2vmin;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.grid-container h2 {
  text-align: right;
}
