.nav-outerDiv {
  flex-direction: column;
  display: flex;
  margin: 10vmin;
  align-items: center;
}

.nav-buttonDiv {
  flex-direction: row;
  display: flex;
}

.nav-button {
  margin: 1vmin;
}
